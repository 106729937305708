<template>
  <div class="">
    <header-portrate />
    <exports-print ref="exportbar" />
    <div class="m-2 pa-2">
    <bread-crumbs :items='items'/>
    <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="underwork" @click="changeBck(1), selected = 1, getCurrentCards()">{{lang.new}}</b-button>
    <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="latecard" @click="changeBck(3), selected = 4, getCurrentCards()">{{lang.late}} - </b-button>
    <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" id="closedcard" @click="changeBck(2), selected = 3, getCurrentCards()">{{lang.closed}}</b-button>
    <b-button class="btn btn-sm btn-secondary mt-10" style="color:#000;width:150px;margin-inline-end:10px;background:#eee !important;font-weight:bold;" @click="$router.push({path: '/car-report'})">{{lang.car_report}}</b-button>
    
    <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" :style="`width:150px;position:absolute;`+lang.lalgin+`:60px;background:transparent;border:none`" @click="resetAll()" v-b-toggle.sidebar-1>
      <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_maint_request}}</div></b-button>

    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row class="mt-3">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <b-input-group class="mt-3">
            <b-form-input placeholder="Start Date" onfocus="(this.type='date')" style="background:#eee;direction:rtl" type="date" v-model="sdate"></b-form-input>
            <b-form-input placeholder="End Date" onfocus="(this.type='date')" style="background:#eee" type="date" v-model="edate"></b-form-input>
            <b-form-input :placeholder="lang.mobile"  style="background:#eee;" v-model="mobile"></b-form-input>
            <b-form-input :placeholder="lang.plate_number" style="background:#eee;" v-model="plate_number"></b-form-input>
            <b-input-group-append style="border-left:1px solid gold">
              <b-button variant="light"
               style="background: gold !important;font-size:14px;color:#000;padding:8px 22px;border:1px solid #ccc;" @click="getCurrentCards()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <b-input-group class="mt-3">
            <b-input-group-append style="border-left:1px solid #CCC">
              <v-btn class="_mybtn1"
                v-b-toggle.sidebar-1
                :style="`display:none;background: green !important;color:#FFF !important;width:190px;margin-`+lang.algin+`:9px`"
              >{{lang.add_maint_request}}</v-btn>
              <v-btn
                v-b-toggle.add_invoice
                v-if="this.$store.state.viewhomeaddinvo"
                style="margin-left:10px;background: blue !important;color:#FFF !important;width:145px;margin-right:9px"
              >{{ lang.add_invoice }}</v-btn>
            </b-input-group-append>
          </b-input-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :style="`direction: `+lang.dir+`;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              width="100%"
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:100px">{{ row.item.cardid }}</td>
                <td nowrap>{{ row.item.customer }}</td>
                <td>{{ row.item.mobile }}</td>
                <td>{{ row.item.plate_number }}</td>
                <td>{{ row.item.car_model }}</td>
                <!-- <td>{{ row.item.car_color }}</td> -->
                <td nowrap>{{ row.item.date_in }}</td> 
                <td nowrap>{{ row.item.date_out }}</td>
                <td>{{ row.item.parts }}</td>
                <td>{{ row.item.ftotal }}</td>
                <td>{{ row.item.vat }}</td>
                <td>{{ row.item.paid }}</td>
                <td>{{ row.item.remaining }}</td>
                <td >{{ row.item.expen }}</td>
                <td :style="`border:1px solid #fff;text-align:center;background:`+row.item._statusbg+`;color:`+row.item._statuscolor+` !important;width:75px !important;`">{{ row.item._status }}</td>
                <td style="margin-left: 5px;width:60px;background:black;border-bottom:2px solid #fff !important;">
                  <v-btn style="width:50px;font-size:0.2em;background:black;box-shadow:none;color:#fff;height:25px !important;" v-b-toggle.view_card @click="ViewItem(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn>
                  <!-- <i class="fas fa-pencil-alt ma-1" style="cursor:pointer;font-size:1.2rem;color:#222" @click="ViewItem(row.item)"></i> -->
                </td>
              </tr>
            </template>
            <template slot="body.append">
                    <tr class="black--text tfoot">
                        <th></th>
                        <th></th>
                        <th></th>
                        <!-- <th></th> -->
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style="font-size:1.2em;text-align:center;">{{lang.totals}}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('parts') }}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('ftotal') }}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('vat') }}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('paid') }}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('remaining') }}</th>
                        <th style="font-size:1.2em;text-align:center;">{{ sumField('expen') }}</th>
                        <th></th>
                        <th></th>
                    </tr>
            </template>
          </v-data-table>
          
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <CreateNewCard ref="createnew" />
    <addInvoice />
    </div>
    <view-card ref="viewCard" />
    <Footer />
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import CreateNewCard from "@/components/CreateNewCard.vue"
  import addInvoice from '@/components/addInvoice.vue'
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  import HeaderPortrate from '@/components/Header-Portrate.vue'
  import Footer from '@/components/Footer.vue'
  import ViewCard from '@/components/view-card.vue'
  import ExportsPrint from '@/components/exports-print.vue'
// import TabsComp from '@/components/tabsComp.vue'
  export default Vue.extend({
    name: 'Home',

    components: {
      CreateNewCard,addInvoice,
        BreadCrumbs,
        HeaderPortrate,
        Footer,
        ViewCard,
        ExportsPrint
    },
    data() {
      return {
        selected:1,
        plate_number:'',
        dBgcolor:'',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        sdate: '',
        edate: '',
        mobile: '',
        excel_fields:{},
        
        tablerows: [
          
        ],
        ishome: true,
        loading: false
      }
    },
    created() {
      // this.$router.push({path: '/invoicelist'})
      this.getCurrentCards();
      setInterval(() => {this.getCurrentCards();},20000)
      this.getExcelHead();
      // setTimeout(() => this.changeBck(1),100);
      setTimeout(() => this.iamin(),100);
    },
    methods: {
      preparedata(){
        let t = this.headers;
        let data = this.tablerows;
        let hdata = [];
        let exdata = [];
        for(let i=0;i<t.length;i++){
          hdata.push(t[i].text);
        }
        exdata.push(hdata);
        for(let k=0;k<data.length;k++){
          let x = [];
            x.push(data[k].cardid);
            x.push(data[k].customer);
            x.push(data[k].mobile);
            x.push(data[k].plate_number);
            x.push(data[k].car_model);
            x.push(data[k].date_in);
            x.push(data[k].date_out);
            x.push(data[k].parts);
            x.push(data[k].ftotal);
            x.push(data[k].vat);
            x.push(data[k].paid);
            x.push(data[k].remaining);
            x.push(data[k].expen);
            x.push(data[k]._status);
            exdata.push(x);
        }
        this.$refs.exportbar.exportdata = exdata;
        this.$refs.exportbar.sdate = this.sdate;
        this.$refs.exportbar.edate = this.edate;
        if(this.selected == 1)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_new;
        if(this.selected == 4)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_late;
        if(this.selected == 3)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_closed;
        this.$refs.exportbar.palte_number = this.plate_number;
        this.$refs.exportbar.contact_number = this.mobile;
      },
      resetAll(){
        this.$refs.createnew.resetALl();
      },
      iamin(){
        if("params",typeof this.$route.params.select !== 'undefined'){
          this.changeBck(this.$route.params.doit);
          this.selected = this.$route.params.select;
          this.getCurrentCards();
        }else{
          this.changeBck(1)
        }
      },
      changeBck(i){
        this.sdate = '';
        this.edate = '';
        this.mobile = '';
        this.plate_number = '';
        if(i == 1){
          document.getElementById('underwork').style.backgroundColor = '#000000';
          document.getElementById('underwork').style.color = '#fff';
          document.getElementById('latecard').style.backgroundColor = '#eee';
          document.getElementById('latecard').style.color = '#000';
          document.getElementById('closedcard').style.backgroundColor = '#eee';
          document.getElementById('closedcard').style.color = '#000';
          document.getElementById('underwork').innerHTML = this.lang.new + '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
          document.getElementById('closedcard').innerHTML = this.lang.closed;
          document.getElementById('latecard').innerHTML = this.lang.late;
        }else if(i == 2){
          document.getElementById('underwork').style.backgroundColor = '#eee';
          document.getElementById('underwork').style.color = '#000';
          document.getElementById('latecard').style.backgroundColor = '#eee';
          document.getElementById('latecard').style.color = '#000';
          document.getElementById('closedcard').style.backgroundColor = '#000000';
          document.getElementById('closedcard').style.color = '#fff';
          document.getElementById('underwork').innerHTML = this.lang.new;
          document.getElementById('closedcard').innerHTML = this.lang.closed + '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
          document.getElementById('latecard').innerHTML = this.lang.late;
        }else if(i == 3){
          document.getElementById('underwork').style.backgroundColor = '#eee';
          document.getElementById('underwork').style.color = '#000';
          document.getElementById('latecard').style.backgroundColor = '#000000';
          document.getElementById('latecard').style.color = '#fff';
          document.getElementById('closedcard').style.backgroundColor = '#eee';
          document.getElementById('closedcard').style.color = '#000000';
          document.getElementById('underwork').innerHTML = this.lang.new;
          document.getElementById('closedcard').innerHTML = this.lang.closed ;
          document.getElementById('latecard').innerHTML = this.lang.late+ '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
        }
      },
      getExcelHead(){
        const head = this.headers;
        
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        //   // console.log("xx",this.excel_fields[x]);
        // }
        
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {
        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        if(x != 0) return x;
        else return 0;
      },
      ViewItem(item){
        this.$refs.viewCard.cardid = item.cardid;
        this.$refs.viewCard.card = item;
        this.$refs.viewCard.getCard();
        this.$refs.viewCard.updateChileds();
        this.$refs.viewCard.calckInv();
    
        // if(item.cardid == null || typeof item.cardid === 'undefined'){
        //   return false;
        // }else{
        //   this.$router.push({ path: '/view-card/'+item.cardid });
        // }
      },
      getCurrentCards(){
        // console.log(this.loading);
        
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        if(path == "/home1"){
          this.selected = 3;
        }
        post.append('type','getCardslist'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',this.plate_number);
        post.append('data[status]',this.selected);
        // this.tablerows = [];
        this.loading = true;
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          // console.log(res);
          this.tablerows = res.results.data;
          this.loading = false;
        })
      },
      ExportToExcel(){
        //
      },
      
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
          return [
            {
              text: this.lang.cardid,
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.customer_name,
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.mobile,
              align: 'center',
              filterable: false,
              value: 'mobile',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.plate_number,
              align: 'center',
              filterable: false,
              value: 'plate_number',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.car_model,
              align: 'center',
              filterable: false,
              value: 'car_model',
              sortable: false,
              class: 'pa-0'
            },
            // {
            //   text: this.lang.car_color,
            //   align: 'center',
            //   filterable: false,
            //   value: 'car_color',
            //   sortable: false,
            // },
            {
              text: this.lang.received,
              align: 'center',
              filterable: false,
              value: 'sdate',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.delivered,
              align: 'center',
              filterable: false,
              value: 'ddate',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.spare_partes,
              align: 'center',
              filterable: false,
              value: 'parts',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.total,
              align: 'center',
              filterable: false,
              value: 'ftotal',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.vat,
              align: 'center',
              filterable: false,
              value: 'vat',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.paid,
              align: 'center',
              filterable: false,
              value: 'paid',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.remain,
              align: 'center',
              filterable: false,
              value: 'remaining',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.expenses,
              align: 'center',
              filterable: false,
              value: 'expen',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.status,
              align: 'center',
              filterable: false,
              value: '_status',
              sortable: false,
              class: 'pa-0'
            },
            {
              text: this.lang.action,
              align: 'center',
              filterable: false,
              value: '',
              sortable: false,
              class: 'pad-0'
            }
        ]
      },
      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },
      xstatus: function() {
        return [
          {
              value: 1,
              text: this.lang.inprogress
          },
          {
              value: 3,
              text: this.lang.closed
          },
          {
              value: 2,
              text: this.lang.not_invoiced
          },
        ]
      },
      tabs: function() {
        return [
          {
            index:0,name:this.lang.inprogress,href:'/home',class:'mybtn',
          },
          {
            index:2,name:this.lang.closed,href:'/home1',class:'mytab2',
          },
        ]
      }
    },
  })
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
</style>

