import Vue from 'vue'
import Vuex from 'vuex'
import billStore from './modules/bill';
import inOutReport from './modules/inoutreport';
import customerSupport from './modules/customerSupport';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMessage: true,
    customers: [],
    COOKIEPhase: 'SAMCCCC-Cookies',
    LangCooki: 'SAMCLANG-Cookies',
    SAMCOTEC: {
      r_path: '/api/index.php', 
      e_path: '../api/excel.php', 
      pd_path: '../api/pdf.php',
    },
    currentMenu: "CurrentMenu",
    vcurrentMenu: "VCurrentMenu",
    currentlink: "currentlink",
    userinfo: [],
    lang:{
      en: {
        langname: 'en',
        dir: 'ltr',
        ldir: 'rtl',
        algin: 'left',
        lalgin: 'right',
        invoiceno: 'Invoice No.',
        plate_number: 'Plate No.',
        main_menu: 'Main Menu',
        search: 'Search',
        invoices: 'Invoices',
        add_invoice: 'Add invoice',
        invtype: 'Invoice Type',
        customer: 'Customer',
        mobile: 'Mobile',
        plate: 'Plate',
        model: 'Model',
        inv_date: 'Date',
        total: 'Total',
        vat: 'VAT',
        ftotal: 'Total inc. VAT',
        paid: 'Paid',
        remain: 'Remain',
        remain_amount: 'Remianing Amount',
        action: 'Action',
        status: 'Status',
        view: 'View',
        totals: 'Totals',
        close: 'Close',
        customer_name: 'Customer Name',
        customer_type: 'Customer Type',
        company_name: 'Company name',
        comapny_address: 'Company address',
        company_vatid: 'VAT Number',
        car_model: 'Model',
        car_year: 'Car Year made',
        car_color: 'Color',
        invoice_type: 'Invoice type',
        vat_type: 'VAT type',
        payment_method: 'Payment method',
        saler_name: 'Saler name',
        invoice_date: 'Invoice date',
        invoice_details: 'Invoice details',
        item_code: 'Item code',
        description: 'Description',
        qtty: 'QTTY',
        item_price: 'Item price',
        discount: 'Discount',
        create_invoice: 'Create Invoice',
        cash_payment: 'Cash method',
        span_payment: 'Span method',
        add: 'Add',
        individual_client: 'Individual client',
        company_clinet: 'Company client',
        prices_include_vat: 'Prices include vat',
        prices_exclusive_vat: 'Prices exclusive of vat',
        prices_without_vat: 'Without VAT',
        none_paid_invoice: 'None Paid Invoice',
        paid_invoice: 'Paid Invoice',
        bank_payment: 'Bank Transfer',
        multi_payments: 'Multi payment method',
        required_field: 'This field is required',
        you_can_not_add_empty_invoice: 'Can not add empty invoice',
        company_ifnormation_required: 'Company information required',
        invoice_added: 'Invoce Added',
        can_not_add_item_withoud_discription: 'Can not add item without description',
        item_qtty_must_be_more_than_zero: 'Can not add item with zero QTY',
        item_price_must_be_more_than_zero: 'Can not add item with zero price',
        print_invoice: 'Print Invoice',
        view_pdf: 'View PDF',
        invoice_return: 'Return Invoice',
        make_payment: 'Make a payment',
        invoice_tme: 'Invoice Time',
        return_date: 'Return Date',
        return_time: 'Return time',
        return_case: 'Return resone',
        total_exclusive_vat: 'Total exclusive vat',
        total_include_vat: 'Total include VAT',
        payments: 'Payments',
        payment_date: 'Payment date',
        payment_time: 'Payment time',
        payment_amount: 'Payment amount',
        payment_cash: 'Payment cash',
        payment_span: 'Payment Span',
        payment_bank: 'Payment Bank',
        return_invoice: 'Return Invoice',
        return_amount: 'Return amount',
        return: 'Return',
        system_modules: 'Modules',
        customers: 'Customers',
        quotations: 'Quotations',
        accounting: 'Accounting',
        envintory: 'Envintory',
        reports: 'Reports',
        settings: 'Settings',
        logout: 'Logout',
        invoice_notes: 'Invoice Notes',
        add_customer: 'Add Customer',
        additional_mobile: 'Additional Number',
        client_statement: 'Statements',
        mobil_10_digits: 'Mobile No. Must be 10 digits',
        customer_added: 'Customer Added sucsessfuly',
        add_quotation: 'Add Quotation',
        quotnumber: 'Quot No.',
        salesman: 'Salesman',
        quot_date: 'Quotation date',
        quotation_details: 'Quot Details',
        delete: 'Delete',
        company_client: 'Company Client',
        can_not_add_empty_row: 'Can not add empty row',
        please_add_company_info: 'Please add company information',
        quotation_added: 'Quotatin added',
        can_not_add_row_without_discription: 'Can not add row without discription',
        can_not_add_row_with_qtty: 'Please add qtty more than 0',
        date: 'Date',
        credit: 'Credit',
        depit: 'Depit',
        new_quotations: 'New quotations',
        invoiced_quots: 'Invoiced Quots',
        canceled_quots: 'Canceled Quots',
        quotation_no: 'Quot No.',
        printQuot: 'Print Quot.',
        cancel_quto: 'Cancel Quot.',
        quot_time: 'Quot Time',
        print: 'Print',
        cash_total: 'Cash Total',
        span_total: 'SPAN total',
        voutcher_no: 'Voutcher No.',
        receive_from: 'Receive From',
        receive_by: 'Receive By',
        cash: 'Cash',
        span: 'SPAN',
        received_voucher: 'Catch Receipt',
        exchange_voucher: 'exchange voucher',
        employees: 'Employees',
        recurring_expensess: 'Recurring Expensess',
        add_voutchre: 'Add Voutcher',
        voutcher_resone: 'Voutcher Resone',
        emp_name: 'Employee name',
        id_number: 'ID number',
        basic_salary: 'Basic Salary',
        home_allownance: 'Home Allownance',
        trans_allownance: 'Transport Allownance',
        food_allown: 'Food Allownance',
        other_allown: 'Other Allownance',
        goze_perc: 'GOZE',
        percentate: 'Percentage',
        update: 'Update',
        add_employee: 'Add employee',
        add_expenses: 'Add Expenses',
        expense_type: 'Expense Type',
        yearly: 'Yearly',
        midterm: 'Midterm',
        quarterly: 'Quarterly',
        monthly: 'Monthly',
        daily: 'Daily',
        expense_total: 'Expense Total',
        expense_period: 'Expense Period',
        calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
        office_rent: 'Office Rent',
        add_maint_request: 'Add Maint. requests',
        cardid: 'Card No.',
        received: 'Received',
        delivered: 'Delivered',
        spare_partes: 'Spare Parts',
        spare_parts: 'Spare Parts',
        main_info: 'Main info',
        received_date: 'Received date',
        delivery_date: 'Delivery date',
        customer_info: 'Customer Info',
        company_info: 'Company Info',
        car_info: 'Car Info',
        received_status: 'Received Status',
        work_description: 'Work Description',
        worker: "Worker",
        work_price: 'Work Price',
        advanced_payment: 'Advanced payment',
        attachement: 'Attatchment',
        choose_file: 'Choose File',
        save: 'Save',
        additional_information: 'Additional Information',
        work_description_required: 'Work Description Required',
        added_sucess: 'Added Success',
        add_user: 'Add user',
        update_user: 'update user',
        users: 'Users',
        user_name: 'Full name',
        login_name: 'Username',
        permission: 'Permissions',
        last_login: 'Last Login',
        save_login_info: 'Save Login Info',
        password: 'Password',
        export_excel: 'Export excel',
        add_purcahse: 'Add purcahse',
        vendor: 'Vendor',
        purchase: 'Purchase',
        inventory: 'Inventory',
        item_name: 'Item name',
        sales_invoices: 'Sales Invocies',
        sales: 'Sales',
        vat_required_return: 'VAT Required (Return)',
        vat_report: 'VAT Report',
        dealy_report: 'Dealy report',
        card_report: 'Card Report',
        sales_report: 'Sales Report',
        return_report: 'Return report',
        percentage: 'Percentage',
        income: 'Income',
        expenses: 'Expenses',
        expense: 'Expense',
        period_expenses: 'Period Expenses',
        closed_by: 'Closed By',
        return_by: 'Return By',
        shortcust_name: 'Shortcut name',
        vat_number: 'VAT Number',
        system_settings: 'System Settings',
        welcometo: 'Welcome',
        view_invoice  : 'View Invoice',

        added_date: 'Added Date',
        added_time: 'Added Time',
        photo_gallary: 'Photo Gallary',
        print_card: 'Print Card',
        car_description: 'Car description',
        invoice_samary: 'Invoice Summary',
        others: 'Others',
        view_card: 'View Card',
        card_closed: 'Card closed',
        please_check_data: 'Please check the data',
        cant_close_card_when_it_not_fully_paid: 'Please first make all remaining payment',
        card_saved: 'Card Saved',
        customerid: 'Customer ID',

        purchase_invoice: 'Purchase Bills',
        billno: 'Bill No',
        vendor_name: 'Vendor Name',
        vendor_mobile: 'Vendor Contact No.',
        vendor_vatids: 'Vendor VAT No.',
        vendor_address: 'Vendor Address',
        type: 'Type',
        bill_type: 'Bill Type',
        bill_detates: 'Bill Details',
        item_unit: 'Item Unit',
        pace: 'Pace',
        view_purchase_bill: 'View Purchase Bill',
        received_amount: 'Received Amount',
        close_card: 'Close Card',
        chose_file: 'Choose your file',
        upload: 'Upload',
        full_name: 'Full name',
        total_amount: 'Total Amount',
        buy_goods: 'Buy goods',
        sallary: 'Sallary',
        buy_devices: 'Buy devices',
        pay_for_bills: 'Pay bills',
        added_successfuly: 'Successfuly Added',
        nationality: 'Nationality',
        percent: 'Percent',
        group_name: 'Group name',
        permissions: 'Permissions',
        administratior: 'Administrator',
        full_permissions: 'Full permissions',
        accountant: 'Accountant',
        accountatn_permissions: 'Full permissions without setting',
        cashier: 'Cashier',
        cashier_permissions: 'Invoice system and generate cards',
        choose_employee: 'Choose employee',
        projectname: 'Project Name',
        inprogress: 'Progress',
        closed: 'Closed',
        not_invoiced: 'Un Billed',
        vendorid: 'Vendor ID',
        paid_in_advanced: 'Advanced Payment',
        paid_in_advanced_amount: 'Amount',
        installment_number: 'Installment #',
        installment_amount: 'Installment Amount',
        mor_than_zero: 'Please add number more than zero',
        emp_reward: 'Rewarded',
        reward_amount: 'Reward Amount',
        reward_resone: 'Reward resone',
        emp_deducts: 'Deducts',
        deducts_amount: 'Deduct Amount',
        deducts_resone: 'Deduct Resone',
        canceled: 'Canceled',
        notpaid: 'Not Paid',
        workstartdate: 'Work Start Date',
        fix_salary: 'Salary Issue',
        card_expenses: 'Maint. Expenses from inventory',
        invoice_expenses: 'Invoice Expenses',
        expenses_summary: 'Expenses Summary',
        general_expenses: 'General Expenses',
        cards_expenses: 'Maintenance',
        invoices_expenses: 'Invoices',
        salaries: 'Salaries',
        add_general_expense: 'Add Gen. Expense',
        periodically: 'Periodically',
        payit: 'Pay Now',
        pay_expenses: 'Pay Expenses',
        payment_period: 'Payment Period',
        expense_amount: 'Amount',
        othermethod: "Other methods.", 
        invoices_total: 'Invoice Totals', 
        invoices_remain: 'Invoice Remaining', 
        other_expenses: 'Other Exp.', 
        exp_total: 'Exp. total', 
        exp_vat:'Exp. VAT',
        employees_and_users: 'ُEmpoyees',
        building_no: 'Building No.',
        street_name: 'Street Name',
        branch_number: 'Branch No.',
        district: 'District',
        zipcode: 'Zipcode',
        city:'City',
        address: 'Address',
        update_customer: 'Update Customer',
        customer_updated: 'Customer Updated',
        update_all: 'Update all',
        update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
        curency: 'SAR',
        pos: 'Point of Sale',
        cancel: 'Cancel',
        total_after_discount: 'Total After Discount',
        multi_payment: 'Multi Payment Methods',
        paid_amount: 'Paid Amount',
        category_name: 'Category',
        category_name_ar: 'Name Ar',
        category_name_en: 'Name En',
        category_type: 'Cat. Type',
        category_parent: 'Parent',
        subcat_count: 'Sub cats.',
        products: 'Items',
        add_category: 'Add Category',
        main_cat: 'Main Category',
        sub_cat: 'Sub Category',
        income_report: 'Income Report',
        incomes: 'Incomes',
        amountprice: 'Amount',
        itotals_per_paymethod: 'Income Totals per Payment Method',
        ototals_per_paymethod: 'Outcome Totals per Payment Method',
        account_statement: 'Account Statment',
        balance: 'balance',
        accounts: 'Accounts',
        account_balance_report: 'Balance Report',
        openingbalance: 'Opining Balance',
        transaction: 'Transaction Balance',
        account: 'Account',
        vlaue: 'Value',
        chart_of_account: 'Chart Of Account',
        definition: 'Definitions',
        paytypes: 'Payment Method',
        car_report: 'Car Report',
        amounttotal: 'Amount Total',
        namear: 'Arabic Name',
        nameen: 'English Name',
        classid: 'Account No.',
        add_pay_type:'Add payment Type',
        statment: 'Statement',
        class_name: 'Account name',
        fixed_assets: 'Fixed Assets',
        late: 'Late',
        price: 'Price',
        vendors: 'Vendors',
        invoice_total: 'Invoices Total',
        vat_total: 'VAT Total',
        add_vendor: 'Add vendor',
        update_vendor: 'Update Vendor',
        vendor_updated_successufly: 'Vendor Added/Updated successfuly',
        vendor_name_required: 'Vendor Name Required',
        vendor_mobile_required: 'Vendor Mobile Required',
        finance_jvs: 'J-Voucher',
        jvnom: 'JV #',
        notes: 'Notes',
        addJV: 'Add JV',
        bnl_report: 'PNL Report',
        balance_report: 'Balance Report',
        gozi_report: 'GOZI Report',
        sales_crm: 'Sales',
        exp_management: 'Management Exp.',
        maintenance_cards: 'Maintenance Cards',
        customer_info_required: 'Customer name/mobile required',
        delivery_date_required: 'Delivery Date Required',
        car_info_required: 'Car info Required',
        please_choose_worker: 'Please choose worker',
        job_price_required: 'Job price required',
        customer_addedd: 'Custoemr Added',
        new: 'New',
        view_employee: 'View Employee',
        add_new_account: 'Add new account',
        account_name_ar: 'Arabic name',
        account_name_en: 'English name',
        mainaccount: 'Main Account',
        accoutn_option: 'Account Options',
        update_acount: 'Update Account',
        acount_name: 'Account name',
        account_type: 'Account Type',
        default_type: 'Default Type',
        account_integrated: 'Integrated with',
        integrated_with_expenses: 'Expenses Integrated',
        account_name: 'Account name',
        expeneses_type: 'Expenses type',
        projects: 'Projects',
        add_project: 'Add Project',
        additional_method: 'Additional Methods',
        adding_date: 'Adding Date',
        ammount: 'Amount',
        method: 'Method',
        home: 'Home',
        payment_requests:'Payments',
        requests:'Requests',
        ment_req_cards_new: 'Cards Maint. New',
        ment_req_cards_late: 'Cards Maint. Late',
        ment_req_cards_closed: 'Cards Maint. Closed',
        print_pagexport_pdfe: 'ُExport PDF',
        print_page: 'ُPrint Page',
        sent_requests: 'Sents Requests',
        item_cost: 'Item Cost',
        username: 'Username',
        welcome: "Welcome",
        add_product: 'Add Product',
        orders: 'Orders',
        booktime: 'Book Time',
        engine_meter: 'Car meter reading (KM)',
        next_oil_change: 'Change oile after (KM)',
        crt_number: 'CR Number',
        company_name_en: 'Company English Name',
        contact_number: 'Contact number',
        you_cannot_delete_this_item_paid_more_than_total: 'You cannot delete this item, total of this card is less than total paid',
      },
      ar:{
        you_cannot_delete_this_item_paid_more_than_total: 'لا يمكنك حذف هذا العنصر وذلك لأن قيمة الدفعات اكبر من قيمة الكرت',
        company_name_en: 'Company English Name',
        contact_number: 'رقم الاتصال',
        booktime: 'وقت الطلب',
        orders: 'الطلبات',
        add_product: 'اضافة منتج',
        welcome: "مرحبا ",
        username: 'اسم المستخدم',
        item_cost: 'التكلفة',
        sent_requests: 'الارساليات',
        print_page: 'طباعة الصفحة',
        print_pagexport_pdfe: 'تصدير PDF',
        ment_req_cards_new: 'طلبات الصيانة الجديدة',
        ment_req_cards_late: 'طلبات الصيانة المتأخرة',
        ment_req_cards_closed: 'طلبات الصيانة المغلقة',
        payment_requests:'الدفعات',
        requests:'الارساليات',
        home: 'الرئيسية',
        additional_method: 'اجراءات اضافية',
        adding_date: 'تاريخ الاضافة',
        ammount: 'القيمة',
        method: 'العملية',
        add_project: 'اضافة مشروع',
        projects: 'المبيعات',
        expeneses_type: 'نوع المصروفات',
        account_name: 'اسم الحساب',
        integrated_with_expenses: 'ربط المصروفات',
        account_integrated: 'الربط مع',
        default_type: 'قيمة الحساب',
        account_type: 'نوع الحساب',
        acount_name: 'اسم الحساب',
        update_acount: 'تحديث الحساب',
        accoutn_option: 'خيارات الحساب',
        mainaccount: 'الحساب الرئيسي',
        account_name_ar: 'الاسم العربي',
        account_name_en: 'الاسم الانجليزي',
        add_new_account: 'حساب جديد',
        view_employee: 'استعراض موظف',
        customer_addedd: 'تمت اضافة العميل بنجاح',
        please_choose_worker: 'يرجى اختيار منفذ العمل',
        job_price_required: 'يرجى اضافة سعر شغل اليد حتى تستطيع المتابعة',
        car_info_required: 'معلومات السيارة مطلوبة',
        delivery_date_required: 'تاريخ التسليم مطلوب',
        customer_info_required: 'رقم واسم العميل مطلوب',
        maintenance_cards: 'بطاقات الصيانة',
        sales_crm: 'المبيعات',
        exp_management: 'مصروفات ادارية',
        bnl_report: 'الارباح والخسائر',
        gozi_report: 'تقرير التأمينات',
        balance_report: 'الميزانية',
        addJV: 'اضافة قيد',
        notes: 'ملاحظات',
        jvnom: 'رقم القيد',
        vendor_name_required: 'اسم المورد مطلوب',
        finance_jvs: 'القيود',
        vendor_mobile_required: 'رقم اتصال المورد مطلوب',
        vendor_updated_successufly: 'تمت اضافة / تعديل المورد بنجاح',
        update_vendor: 'تعديل مورد',
        add_vendor: 'اضافة مورد',
        invoice_total: 'اجمالي الفواتير',
        vat_total: 'قيمة الضريبة',
        vendors: 'الموردين',
        price: 'السعر',
        late: 'متأخر',
        fixed_assets: 'الاصول الثابتة',
        class_name: 'اسم الحساب',
        add_pay_type: ' طريقة دفع',
        statment: 'كشف حساب',
        namear: 'الاسم العربي',
        nameen: 'الاسم الانجليزي',
        classid: 'رقم الحساب',
        amounttotal: 'اجمالي المبلغ',
        car_report: 'تقرير المركبة',
        definition: 'ربط الشجرة ',
        itotals_per_paymethod: 'اجمالي الدخل بحسب طريقة الدفع',
        ototals_per_paymethod: 'اجمالي المصروفات بحسب طريقة الدفع',
        account_statement: ' كشف حساب',
        account_balance_report: 'تقرير الموازنة',
        openingbalance: 'الرصيد الافتتاحي',
        transaction: 'رصيد العمليات',
        chart_of_account: 'شجرة الحسابات',
        paytypes: 'طرق الدفع',
        balance: 'الرصيد',
        accounts: 'الحسابات',
        incomes: 'الدخل',
        amountprice: 'المبلغ',
        account: 'الحساب',
        vlaue: 'القيمة',
        langname: 'ar',
        dir: 'rtl',
        ldir: 'ltr',
        algin: 'right',
        lalgin: 'left',
        invoiceno: 'رقم الفاتورة',
        plate_number: 'رقم اللوحة',
        main_menu: 'القائمة',
        search: 'بحث',
        invoices: 'الفواتير',
        add_invoice: 'اضافة فاتورة',
        invtype: 'نوع الفاتورة',
        customer: 'العميل',
        mobile: 'رقم الاتصال',
        plate: 'اللوحة',
        model: 'الموديل',
        inv_date: 'التاريخ',
        total: 'المجموع',
        vat: 'الضريبة',
        ftotal: 'الاجمالي مع الضريبة',
        paid: 'المدفوع',
        remain: 'المتبقي',
        remaremain_amountin: 'المبلغ المتبقي على العميل',
        action: 'الاجراء',
        status: 'الحالة',
        view: 'عرض',
        totals: 'المجموع',
        close: 'اغلاق',
        customer_name: 'اسم العميل',
        customer_type: 'نوع العميل',
        company_name: 'اسم الشركة',
        comapny_address: 'عنوان الشركة',
        company_vatid: 'الرقم الضريبي',
        received_amount: 'المبلغ المدفوع',
        car_model: 'الموديل',
        car_year: 'سنة الصنع',
        car_color: 'اللون',
        invoice_type: 'نوع الفاتورة',
        vat_type: 'نوع الضريبة',
        payment_method: 'طريقة الدفع',
        saler_name: 'البائع',
        invoice_date: 'تاريخ الفاتورة',
        invoice_details: 'تفاصيل الفاتورة',
        item_code: 'رمز الصنف',
        description: 'الوصف',
        qtty: 'الكمية',
        item_price: 'سعر الصنف',
        discount: 'الخصم',
        create_invoice: 'إنشاء فاتورة',
        cash_payment: 'الدفع نقدا',
        bank_payment: 'تحويل بنكي',
        span_payment: 'الدفعة بواسطة الشبكة',
        add: 'إضافة',
        individual_client: 'عميل فردي',
        company_clinet: 'عميل شركة',
        prices_include_vat: 'السعر شامل الضريبة',
        prices_exclusive_vat: 'السعر غير شامل الضريبة',
        prices_without_vat: 'بدون ضريبة',
        none_paid_invoice: 'فاتورة آجلة',
        paid_invoice: 'فاتورة نقدية',
        multi_payments: 'الدفع المتعدد',
        required_field: 'الحقل مطلوب',
        view_purchase_bill: 'عرض فاتورة مشتريات',
        you_can_not_add_empty_invoice: 'لا يمكن اضافة فاتورة فارغة',
        company_ifnormation_required: 'يرجى ادخال بيانات الشركة',
        invoice_added: 'تمت اضافة الفاتورة بنجاح',
        can_not_add_item_withoud_discription: 'لا يمكن اضافة صنف بدون وصف',
        item_qtty_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون كمية',
        item_price_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون سعر',
        print_invoice: 'طباعة الفاتورة',
        view_pdf: 'استعراض PDF',
        invoice_return: 'ارجاع الفاتورة',
        make_payment: 'سداد دفعة',
        invoice_tme: 'وقت الفاتورة',
        return_date: 'تاريخ الارجاع',
        return_time: 'وقت الارجاع',
        return_case: 'سبب الارجاع',
        total_exclusive_vat: 'اجمالي بدون ضريبة',
        total_include_vat: 'اجمالي مع ضريبة',
        payments: 'الدفعات',
        payment_date: 'تاريخ الدفعة',
        payment_time: 'توقيت الدفعة',
        payment_amount: 'قيمة الدفعة',
        payment_cash: 'نقدا',
        payment_span: 'شبكة',
        payment_bank: 'تحويل',
        return_invoice: 'ارجاع فاتورة',
        return_amount: 'المبلغ المرتجع',
        return: 'ارجاع',
        system_modules: 'اقسام النظام',
        customers: 'العملاء',
        quotations: 'عروض السعر',
        accounting: 'المحاسبة',
        envintory: 'المخزون',
        reports: 'التقارير',
        settings: 'الاعدادات',
        logout: 'تسجيل خروج',
        invoice_notes: 'ملاحظات الفاتورة',
        add_customer: 'اضافة عميل',
        additional_mobile: 'الرقم الاضافي',
        client_statement: 'كشف حساب',
        mobil_10_digits: 'رقم الجوال يجب ان يكون عشرة ارقام',
        customer_added: 'تمت اضافة العميل بنجاح',
        add_quotation: 'اضافة عرض ',
        quotnumber: 'رقم العرض.',
        salesman: 'البائع',
        quot_date: 'تاريخ العرض',
        quotation_details: 'تفاصيل العرض',
        delete: 'حذف',
        company_client: 'عميل شركة',
        can_not_add_empty_row: 'لا يمكن اضافة صنف فارغ',
        please_add_company_info: 'يرجى اضافة بيانات الشركة',
        quotation_added: 'تمت اضافة العرض بنجاح',
        can_not_add_row_without_discription: 'لا يمكن اضافة صنف بدون وصف',
        can_not_add_row_with_qtty: 'جيب ان تكون الكمية اكبر من 0',
        date: 'التاريخ',
        credit: 'دائن',
        depit: 'مدين',
        new_quotations: 'العروض الجديدة',
        invoiced_quots: 'العروض المفوترة',
        canceled_quots: 'العروض الملغية',
        quotation_no: 'رقم العرض.',
        printQuot: 'طباعة العرض.',
        cancel_quto: 'الغاء العرض.',
        quot_time: 'توقيت العرض',
        print: 'طباعة',
        cash_total: 'المجموع النقدي',
        span_total: 'بطاقات البنك',
        voutcher_no: 'رقم السند.',
        receive_from: 'استلام من',
        receive_by: 'الاستلام بواسطة',
        cash: 'نقدا',
        span: 'بطاقات بنكية',
        received_voucher: 'سندات القبض',
        exchange_voucher: 'سندات الصرف',
        employees: 'الموظفين',
        recurring_expensess: 'مصاريف دورية',
        add_voutchre: 'اضافة سند',
        voutcher_resone: 'سبب السند',
        emp_name: 'اسم الموظف',
        id_number: 'رقم الهوية',
        basic_salary: 'الراتب الاساسي',
        home_allownance: 'بدل السكن',
        trans_allownance: 'بدل النقل',
        food_allown: 'بدل الطعام',
        other_allown: 'بدلات اخرى',
        goze_perc: 'التاميات',
        percentate: 'النسبة',
        update: 'تعديل',
        add_employee: 'اضافة موظف',
        add_expenses: 'اضافة مصروف',
        expense_type: 'نوع المصروف',
        yearly: 'سنوي',
        midterm: 'نصف سنوي',
        quarterly: 'ربع سنوي',
        monthly: 'شهري',
        daily: 'يومي',
        expense_total: 'اجمالي المصروف',
        expense_period: 'مدة المصروف',
        calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
        office_rent: 'اجار المكتب',
        add_maint_request: 'اضافة طلب ',
        cardid: 'رقم البطاقة.',
        received: 'الاستلام',
        delivered: 'التسليم',
        spare_partes: 'قطع الغيار',
        spare_parts: 'قطع الغيار',
        main_info: 'معلومات اساسية',
        received_date: 'تاريخ الاستلام',
        delivery_date: 'تاريخ التسليم',
        customer_info: 'معلومات العميل',
        company_info: 'معلومات الشركة',
        car_info: 'معلومات السيارة',
        received_status: 'حالة الاستلام',
        work_description: 'وصف العمل',
        worker: "العمالة",
        work_price: 'شغل اليد',
        advanced_payment: 'دفعة مقدمة',
        attachement: 'المرفقات',
        choose_file: 'اختر الملف',
        save: 'حفظ',
        additional_information: 'بيانات اضافية',
        work_description_required: 'وصف العمل مطلوب',
        added_sucess: 'تمت الاضافة بنجاح',
        add_user: 'إضافة مستخدم',
        update_user: 'تعديل مستخدم',
        users: 'المستخدمين',
        user_name: 'اسم الموظف',
        login_name: 'اسم الدخول',
        permission: 'الصلاحية',
        last_login: 'آخر دخول',
        save_login_info: 'حفظ بيانات الدخول',
        password: 'كلمة المرور',
        export_excel: 'تصدير اكسل',
        add_purcahse: 'اضافة مشتريات',
        vendor: 'المورد',
        purchase: 'المشتريات',
        inventory: 'المخزون',
        item_name: 'اسم المنتج',
        sales_invoices: 'فواتير المبيعات',
        sales: 'المبيعات',
        vat_required_return: 'قيمة الضريبة المضافة المستحقة (المستردة)',
        vat_report: 'تقرير الضريبة',
        dealy_report: 'تقرير اليومية',
        card_report: 'تقرير البطاقات',
        sales_report: 'تقرير المبيعات',
        return_report: 'تقرير المرتجع',
        percentage: 'النسبة',
        income: 'الدخل',
        expenses: 'المصروفات',
        expense: 'المصروف',
        period_expenses: 'المصاريف الدورية',
        closed_by: 'اغلق بواسطة',
        return_by: 'مرتجع عن طريق',
        shortcust_name: 'الاسم المختصر',
        vat_number: 'الرقم الضريبي',
        system_settings: 'اعدادات النظام',
        welcometo: 'مرحبا ',
        added_date: 'تاريخ الاضافة',
        added_time: 'التوقيت',
        photo_gallary: 'معرض الصور',
        print_card: 'طباعة البطاقة',
        car_description: 'وصف السيارة',
        invoice_samary: 'ملخص البطاقة',
        others: 'أخرى',
        view_card: 'استعراض البطاقة',
        card_closed: 'تم اغلاق البطاقة',
        please_check_data: 'يرجى التحقق من البيانات',
        cant_close_card_when_it_not_fully_paid: 'يجب استكمال الدفعات قبل اغلاق البطاقة',
        card_saved: 'تم حفظ البطاقة',
        view_invoice  : 'استعراض الفاتورة',
        customerid: 'معرف العميل',
        purchase_invoice: 'فاتورة مشتريات',
        billno: 'رقم الفاتورة',
        vendor_name: 'اسم المورد',
        vendor_mobile: 'رقم المورد.',
        vendor_vatids: 'الرقم الضريبي للمورد.',
        vendor_address: 'عنوان المورد',
        type: 'النوع',
        bill_type: 'نوع الفاتورة',
        bill_detates: 'تفاصيل الفاتورة',
        item_unit: 'الوحدة',
        pace: 'حبه',
        close_card: 'اغلاق البطاقة',
        chose_file: 'اختر الملف',
        upload: 'تحميل',
        full_name: 'الاسم كاملا',
        total_amount: 'اجمالي المبلغ',
        buy_goods: 'شراء بضاعة',
        sallary: 'راتب',
        buy_devices: 'شراء اجهزة',
        pay_for_bills: 'دفع فواتير',
        added_successfuly: 'تمت الاضافة بنجاح',
        nationality: 'الجنسية',
        percent: 'النسبة',
        group_name: 'اسم المجموعة',
        permissions: 'الصلاحيات',
        administratior: 'الادارة العامة',
        full_permissions: 'صلاحيات كاملة',
        accountant: 'المحاسبة',
        accountatn_permissions: 'كل الصلاحيات بدون الاعدادات',
        cashier: 'الكاشير',
        cashier_permissions: 'الفواتير والبطاقات',
        choose_employee: 'اختر الموظف',
        employees_and_users: 'الموظفين',
        salary_table: 'مسير الرواتب',
        projectname: 'اسم المشروع',
        inprogress: 'تحت العمل',
        new: 'جديد',
        closed: 'مغلق',
        not_invoiced: 'غير مفوتر',
        added_by: 'بواسطة',
        vendorid: 'معرف المورد',
        paid_in_advanced: 'السلف',
        paid_in_advanced_amount: 'قيمة السلفة',
        installment_number: 'عدد الدفعات',
        installment_amount: 'قيمةالدفعة',
        mor_than_zero: 'يرجى اضافة رقم اكبر من الصفر',
        emp_reward: 'المكافآت',
        emp_deducts: 'الخصومات',
        reward_amount: 'قيمة المكافأة',
        reward_resone: 'سبب المكافأة',
        deducts_amount: 'قيمة الخصم',
        deducts_resone: 'سبب الخصم',
        canceled: 'ملغي ',
        notpaid: 'لم يتم السداد',
        emp_custody: 'العهد',
        custody_amount: 'مبلغ العهدة',
        workstartdate: 'تاريخ بدء العمل',
        fix_salary: 'اصدار الراتب',
        card_expenses: 'مصروفات الصيانة من المخزون',
        invoice_expenses: 'مصاريف الفاتورة',
        expenses_summary: 'ملخص المصاريف',
        general_expenses: 'مصاريف عامة',
        cards_expenses: 'الصيانة',
        invoices_expenses: 'الفواتير',
        salaries: 'الرواتب',
        add_general_expense: ' مصروفات ',
        periodically: 'دورية',
        payit: 'سداد',
        pay_expenses: 'تسديد المصاريف',
        payment_period: 'مدة السداد',
        expense_amount: 'المبلغ',
        othermethod: "طرق دفع ", 
        invoices_total: 'اجمالي الفواتير', 
        invoices_remain: 'متبقي الفواتير', 
        other_expenses: 'مصاريف اخرى', 
        exp_total: 'اجمالي المصروفات', 
        exp_vat:'ضريبة المصروفات',
        building_no: 'رقم المبنى',
        street_name: 'اسم الشارع',
        branch_number: 'الرقم الفرعي',
        district: 'الحي',
        zipcode: 'الرمز البريدي',
        city:'المدينة',
        address: 'العنوان',
        update_customer: 'تعديل عميل',
        customer_updated: 'تم تعديل العميل',
        update_all: 'تعديل الكل',
        update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
        curency: 'ريال',
        pos: 'نقطة بيع',
        cancel: 'الغاء',
        total_after_discount: 'الاجمالي بعد الخصم',
        multi_payment: 'الدفع المتعدد',
        paid_amount: 'المبلغ المدفوع',
        product_categories: 'أقسام المخزون',
        category_name: 'القسم',
        category_name_ar: 'اسم القسم عربي',
        category_name_en: 'اسم القسم انجليزي',
        category_type: 'نوع القسم',
        category_parent: 'القسم الرئيسي',
        subcat_count: 'الاقسام الفرعية',
        products: 'المنتجات', 
        add_category: 'اضافة قسم',
        main_cat: 'قسم رئيسي',
        sub_cat: 'قسم فرعي',
        income_report: 'تقرير الدخل',
        engine_meter: 'قراءة عداد السيارة (كم)',
        next_oil_change: 'تغيير الزيت بعد (كم)',
        crt_number: 'السجل التجاري',
      }
    },
    caneditinvoicedate: true,
    settings: [],
    connected: false,
    products: [],
    smsTemp:[],
    hometitle: 'طلبات الصيانة',
    hometitle_en: 'Maintenance Requests',
    carsys: false,
    debug:0,
    licenseType: {
      otherproducts: false,
      cars: true,
      parts: false,
      bill: true,
      quotation: true,
      clientStatment:true,
      carinfo: false,
      salarysys: true,
      showproject: false,
      crm: false,
      showAddedBy: true,
      printDelNotes: true,
      agentPurchase: true,
      expenses: true,
      POSREST: false,
      POSRET: false,
      families: false,
      fullversion:true,
      orderlic: false,
      onlySalesPurchase: false,
      isBill: false,
      isSupport: false,
    },
    /**
     * if ecars {cars: true,carinfo: false, onlySalesPurchase: false }
     */
    viewhomeaddinvo:false,
    zeroPrice:true,
    // SystemName: 'Fatoora',
    SystemName: 'SAMCOTEC',
    Version: "3.0.3",
    deflang: 'ar',
    group_id: '',
    // 1 for ecar
    // 5 for invoice
    // 3 for resturant 
    // 4 for retail
    // 12 for clientlist
    // 2 for customerlist
    // 13 for orders
    headerIcone: 1
  },
  getters:{
    
  },
  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    billStore,
    inOutReport,
    customerSupport
  }
})
