<template>
  <div style="margin:5px;" class="rowTitle">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
        {{$parent.$parent.lang.work_price}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <v-form ref="formmem">
            <v-simple-table striped hover style="width:100%;">
              <template v-slot:default>
                <thead> 
                <tr>
                  <th style="direction:rtl;padding:1px;text-align:center;">{{$parent.$parent.lang.description}}</th>
                  <!-- <th style="direction:rtl;padding:1px;text-align:center;width:90px">التكلفة</th> -->
                  <th style="direction:rtl;padding:1px;text-align:center;width:90px">{{$parent.$parent.lang.item_price}}</th>
                  <th style="direction:rtl;padding:1px;text-align:center;width:86px;" v-if="showbuttoms"></th>
                </tr>
                </thead>
                <tbody>
                <tr 
                    v-for="(item,index) in items"
                    :key="item.id"
                >
                  <td style="white-space:normal !important;">{{ item.description }}</td>
                  <!-- <td>{{ item.cost }}</td> -->
                  <td>{{ item.price }}</td>
                  <td v-if="showbuttoms">
                    <v-btn class="ma-1" :id="`wdel_`+item.id" style="height:25px;background:red;color:#FFF;width:70px" @click="DeleteLabor( index )">{{$parent.$parent.lang.delete}}</v-btn>
                    <!-- <v-btn class="ma-1" :id="`wdone_`+item.id" style="height:25px;background:green;color:#FFF;width:70px" @click="finishJob( index )">انتهت</v-btn> -->
                  </td>
                </tr>
                <tr v-if="showbuttoms">
                  <td style="white-space:normal !important;">
                    <v-combobox
                        v-model="description"
                        :items="carworkdes"
                        :rules="fieldRules"
                      ></v-combobox>
                    <!-- <b-input v-model="description" id="description_" style="font-size: 0.8rem"></b-input> -->
                  </td>
                  <td style="display:none">
                    <b-input v-model="cost" id="cost_" style="font-size: 0.8rem"></b-input>
                  </td>
                  <td>
                    <b-input v-model="price" id="price_" :rules="numberRules" style="font-size: 0.8rem"></b-input>
                  </td>
                  <td>
                    <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="EditLabor( 0 )">{{$parent.$parent.lang.add}}</v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-form>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';

export default {
  name: 'CVSubletsTable',
  components: {},
  props: {
    ftotal: Number,
    totalPaid: Number
  },
  data: () => ({
    showbuttoms: true,
    items:[
      
    ],
    description: '',
    cost: '',
    price: '',
    cardid: '',
    carworkdes:[],
    fieldRules:[
      v => !!v || 'الحقل مطلوب',
    ],
    numberRules:[
      val => {if(val <= 0) return `ادخل رقم غير صفر`}
    ],
    
  }),
  computed: {
    iscancel: function(){
      return !this.$parent.showaddinv
    },
    canceled: function(){
      return !this.$parent.showaddinv;
    }
  },
  created(){
    // this.getService();
    // this.getWorkerds();
  },
  methods: {
    finishJob(id){
      this.$parent.$parent.NewDone.push(this.items[id]);
      document.getElementById('wdone_'+this.items[id].id).style.display = "none";
      document.getElementById('wdel_'+this.items[id].id).style.display = "none";
    },
    EditLabor(id){
      
      if (this.$snotify.notifications.length > 0) {
              this.$snotify.notifications.forEach(notification => {
                  this.$snotify.remove(notification.id)
              });
          }
      if(!this.$refs.formmem.validate()){
        return false;
      }
      if(this.price == 0){
        //
      }
      const formdata = new FormData();
      formdata.append('type','updateCItem');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','service');

      if(id == 0){
        formdata.append('data[description]',this.description);
        formdata.append('data[cost]',this.cost);
        formdata.append('data[price]',this.price);
        formdata.append('data[total]',0);
        formdata.append('data[qty]',1);
        axios.post(
          this.$store.state.SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          console.log(res);
          this.cost = 0;
          this.price = 0;
          this.description = '';
          this.getService();
          this.$parent.$parent.getCard()
        })
      }
    },
    DeleteLabor(id){
      // const x = 1;
      // if(x == 1){
      //   if(this.items[id].id !== 'undefined'){
      //     this.$parent.$parent.NewDel.push(this.items[id]);
      //     this.items.splice(id);
      //     return false;
      //   }else{
      //     this.$parent.$parent.NewDel.splice(this.items[id]);
      //     this.items.splice(id);
      //   }
      //   return false;
        
      // }
      // alert(
      //   this.ftotal + ", "+ this.totalPaid
      // );
      // return false;
      const def = this.ftotal - this.items[id].price;
      if( def < this.totalPaid ){
        
        this.$snotify.error(this.$parent.$parent.lang.you_cannot_delete_this_item_paid_more_than_total, 'تنبيه', {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                    text: this.$parent.$parent.lang.close, 
                    action: (toast) => {
                        this.$snotify.remove(toast.id); 
                    } 
                },
            ]
        });
        return false;
      }
      const ritem = this.items[id];
      this.items.splice(id);
      const post = new FormData();
      post.append('type','deletedItem');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[id]',ritem.id);
      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
          }
        }
      )

    },
    getWorkerds(){
      this.carworkdes = [];
      const post = new FormData();
      post.append('type','getWorkerds');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]','1');
      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
          }
        }
      )
    },
    getService(){
      const formdata = new FormData();
      formdata.append('type','getCItem');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','service');
       axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          this.items = res.results.data.results;
        });
    }
  }
}
</script>
<style>
.rowTitle{
  background:#FFF;
  color:#000;
  font-size:14px;
  text-align: right;
  padding:3px 3px 5px 3px;
}
</style>